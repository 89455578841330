@use '../core/_main';


.nav-search-icon {
    width: 20px;
}
// .nav-phone {
//     padding-left: 5px;
// }


.nav-search-container {
    margin-left: 20px;
    margin-right: 20px;
}

.nav-call-text {
    margin-top: 3.5px;
}

.nav-phone {
    margin-top: 0px;
    padding-left: 5px;
}
.nav-element {
    transition: all;
}

.nav-element-hidden{
    
    &.out {
        animation: fadeOut .5s forwards;
    }

    &.in {
        animation: fadeIn .5s forwards;
    }
}