.main-logo {
  width: 150px;
  transition: all 0.5s ease;
  //z-index: 1100 !important;
}

@media screen and (min-width: 468px) {
  .main-logo {
    width: 200px;
  }
}

@media screen and (min-width: 800px) {
  .main-logo {
    width: 300px;
  }
}
