@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


::root {
  --bs-font-sans-serif: Prompt;
}
$primary-color: #aa2032;
$primary-accent: #bc1f31;
$focus-color: #ffa5b0;
$secondary-color: rgba(28,28,28,1);

$info-color: #666666;
$info-dark: #c4c4c4;


$background-light-grey : #e9e5e5;
$border-light-grey : #efefef;
$text-light: #ffffff;

$pagination-focus-box-shadow: 0 0 0 0.25rem rgba(162, 34, 62, 0.25);

:export {
  primaryColor: $primary-color;
  primaryAccentColor: $primary-accent;
  focusColor: $focus-color;
  secondary-color: $secondary-color;
  infoColor: $info-color;
  text-light: $text-light;
  bgLightGrey: $background-light-grey;
}
// images
$menu-background-image: url("/img/tyler.webp") center center;

// this allows me to use these colors within bootstrap
$theme-colors: (
  "primary": $primary-color,
  "primary-accent": $primary-accent,
  "focus": $focus-color,
  "secondary": $secondary-color,
  "info": $info-color,
  "infoDark": $info-dark,
  "lightGrey": $background-light-grey,
  "borderLightGrey": $border-light-grey
  
);

* {
  font-family: "Prompt", sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 0.05rem;
}

.nav-active {
  //overflow: hidden;
}

.nav-inactive {
  overflow: auto;
}


li::marker{
  color:#bc1f31
}

a:link {
  text-decoration: none;
}

.btn:focus, .btn.Focus{
  box-shadow: none !important;
 
}

.frontvideo{
  position: block;
  
  height: 400px;
  width: auto;

}
.videoContainer{
  // height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

@media screen and (min-width: 700px) {
  
  .frontvideo{
    display: block;
    transform: translateX( 0);
    height: auto;
    // max-height: 700px;
    width: 100%;
  }

  .videoContainer{
    height: 600px;
    overflow-y: hidden;
    display: inline;
    width: auto;
  }
}

@media screen and (min-width: 1075px) {
  
  .frontvideo{
    display: block;
    transform: translateX( 0);
    height: auto;
    // max-height: 700px;
    width: 100%;
  }

  .videoContainer{
    height: 600px;
    align-items: center;
    overflow-y: hidden;
    display: block;
    width: auto;
  }
}