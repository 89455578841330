.text_line {
    color: white;
    font-weight: 200;
    
}
.top-bar {
    height: 4rem;
}

@media screen and (min-width: 469px ) {
    .top-bar {
        height: 2.6rem;
    }
}

