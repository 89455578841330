$animation_timing: 0.35s;


.burger-container {
  cursor: pointer;

}
.burger {
  display: block;
  width: 25px;
  height: 3px;
  background: #fcfcfc;

  &.top {
    transform: translateY(4px);

    &.open {
      animation: animateTop $animation_timing forwards;
    }

    &.close {
      animation: animateTopReverse $animation_timing forwards;
    }
  }
  &.middle {
    transform: translateY(8px);

    &.open {
      animation: animateMiddle $animation_timing forwards;
    }
    &.close {
      animation: animateMiddleReverse $animation_timing forwards;
    }
  }

  &.bottom {
    transform: translateY(12px);

    &.open {
      animation: animateBottom $animation_timing forwards;
    }
    &.close {
      animation: animateBottomReverse $animation_timing forwards;
    }
  }
}

@keyframes animateTop {
  0% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: rotate(45deg) translate(8px, 7px);
    // transform: translateY(10px);
  }
}

@keyframes animateTopReverse {
  0% {
    transform: rotate(45deg) translate(8px, 7px);
  }
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(4px);
  }
}

@keyframes animateBottom {
  0% {
    transform: translateY(12px);
  }
  60% {
    transform: translateY(4px);
  }
  100% {
    transform: rotate(-45deg) translate(-3px, 3px);
    // transform: translateY(4px);
  }
}

@keyframes animateBottomReverse {
  0% {
    transform: rotate(-45deg) translate(-3px, 3px);
  }
  60% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(12px);
  }
}

@keyframes animateMiddle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animateMiddleReverse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
