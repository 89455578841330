.flipper-line{
    opacity:0;
    transition: all .25s ease;
    z-index: -1;
    width: 100vw;
    transform: translatex(-3.2%);
}

.flipper-choice{
    cursor: pointer;
}

.flipper-show{
    opacity: 1;
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .flipper-line{
       
        width: 80vw;
    }
}
