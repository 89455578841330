.form-message{
    transform: translateY(1rem);
    
    transition: all .5s ease;
    opacity: 0;
    display: none;
    z-index: 0;
}

.form-message-show{
    opacity:1;
    transform: translateY(-.5rem);
}
.form-message-block{
    display: block;
}

.form-success {
    background-color:forestgreen;
}

.form-failure {
    background-color:darkred ;
} 