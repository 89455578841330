.secondary__form-container{
    
    top: 265px !important;
    transition: all .75s ease;
}

@media screen and (min-width: 1200px) {
    .secondary__form-container{
        
        top: 465px !important;
    }
    
}