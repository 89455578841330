.top-image-banner {
  height: 175px;
  background-color: $secondary-color;
}

@media screen and (min-width: 576px) {
  .top-image-banner {
    height: 225px;
  }
}


.main-header {
  position: relative;


  &__links-container {
    margin-top: 1%;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      max-width: 90%;
      display: flex;

      & > i {
        flex-grow: 1;
        object-fit: contain;

      }
    }
  }

  &__link {
    //display: inline;
    cursor: pointer;
    display: flex;

    & > img {
      flex-grow: 1;
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      filter: contrast(1.5) saturate(1.3);
    }
  }

  &__divider {
    cursor: pointer;
    display: flex;

    & > img {
      flex-grow: 1;
      width: 100%;
      object-fit: contain;
    }
  }

  &__link-btn {
    cursor: pointer;
    display: flex;


    &-container {
      margin-top: 1%;
      position: absolute !important;
      bottom: 26.5%;
      left: 20%;
      display: flex;
      width: 100%;
      
      justify-content: center;
      
      & > div {
        max-width: 35%;
        display: flex;

        & > img {
          flex-grow: 1;
          width: 100%;
          object-fit: contain;
        }
      }

     
    }
  }


}
